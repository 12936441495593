import SupportedLanguages from '@/domains/configurations/SupportedLanguages'
import { OfferCategory } from '@/domains/offerCategory/offerCategory'
import { IProgram } from '@/domains/program'
import { VendorAndSettingsData, VendorByZoneAttributes } from '@/domains/vendors'
import {
	MEMBERSHIP_STORE_KEY,
	MULTI_VENDOR_ATTRIBUTES_STORAGE_KEY,
	MULTI_ZONE_LOCAL_STORAGE_KEY,
} from '@/utils/constants'
import { setStorageItem } from '@/utils/storageUtil'
import { createStore } from 'effector'
import * as GlobalEvents from './GlobalEvents'
import GlobalState, { globalInitialState as initialState } from './GlobalState'

const setMembershipStore = (vendorId: string | null, tier: string | null, zone: string | null): void => {
	const membershipData = { vendorId, tier, zone }
	setStorageItem(MEMBERSHIP_STORE_KEY, membershipData)
}

const setTheVendorInTheStorage = (state: GlobalState): void => {
	if (state.selectedZone && state.selectedVendorId) {
		setStorageItem<VendorByZoneAttributes>(MULTI_VENDOR_ATTRIBUTES_STORAGE_KEY, {
			[state.selectedZone]: {
				vendorId: state.selectedVendorId,
			},
		})
	} else {
		setStorageItem<VendorByZoneAttributes>(MULTI_VENDOR_ATTRIBUTES_STORAGE_KEY, {})
	}
}

const onLoad = (state: GlobalState): GlobalState => ({ ...state, isLoading: true })

const onLoadDone = (state: GlobalState): GlobalState => ({ ...state, isLoading: false })

const onSelectProgram = (state: GlobalState, selectedProgram: IProgram | null): GlobalState => ({
	...state,
	selectedProgram,
})

const onSelectZone = (state: GlobalState, selectedZone: string | null): GlobalState => {
	setStorageItem(MULTI_ZONE_LOCAL_STORAGE_KEY, selectedZone ?? '')
	setMembershipStore(state.selectedVendorId, null, selectedZone)
	return { ...state, selectedZone, selectedTierKey: null }
}

const onSetPageReady = (state: GlobalState, isPageReady: boolean): GlobalState => ({
	...state,
	isPageReady,
})

const onSetVendorAndSettings = (state: GlobalState, data: VendorAndSettingsData): GlobalState => {
	const newState = {
		...state,
		...data,
	}
	setTheVendorInTheStorage(newState)
	setMembershipStore(newState.selectedVendorId, newState.selectedTierKey, newState.selectedZone)
	return newState
}

const onClearVendorData = (state: GlobalState): GlobalState =>
	onSetVendorAndSettings(state, {
		selectedVendorId: null,
		vendorOptionsMap: {},
		selectedProgram: state.selectedProgram,
		selectedTierKey: null,
		tiersMap: {},
	})

const onSelectVendor = (state: GlobalState, vendorId: string): GlobalState => {
	const currentVendor = state.vendorOptionsMap[vendorId]
	const newState = {
		...state,
		selectedVendorId: vendorId,
		selectedProgram: currentVendor.programs[0],
	}
	setTheVendorInTheStorage(newState)
	return newState
}

const onSelectTier = (state: GlobalState, selectedTierKey: string): GlobalState => {
	const newState = {
		...state,
		selectedTierKey,
	}
	setMembershipStore(newState.selectedVendorId, selectedTierKey, newState.selectedZone)
	return newState
}

const onSetSupportLanguage = (state: GlobalState, supportedLanguages: SupportedLanguages): GlobalState => ({
	...state,
	supportedLanguages,
})

const onSetOfferCategories = (state: GlobalState, offerCategories: OfferCategory[]): GlobalState => ({
	...state,
	offerCategories,
})

const updatedInitialState: GlobalState = {
	...initialState,
}

const GlobalStore = createStore<GlobalState>(updatedInitialState)
	.on(GlobalEvents.load, onLoad)
	.on(GlobalEvents.loadDone, onLoadDone)
	.on(GlobalEvents.selectProgram, onSelectProgram)
	.on(GlobalEvents.selectZone, onSelectZone)
	.on(GlobalEvents.setPageReady, onSetPageReady)
	.on(GlobalEvents.setVendorAndSettings, onSetVendorAndSettings)
	.on(GlobalEvents.clearVendorData, onClearVendorData)
	.on(GlobalEvents.selectVendor, onSelectVendor)
	.on(GlobalEvents.selectTier, onSelectTier)
	.on(GlobalEvents.setSupportLanguage, onSetSupportLanguage)
	.on(GlobalEvents.setOfferCategories, onSetOfferCategories)

export default GlobalStore
