import { TFunction } from 'react-i18next'

interface ErroResponse {
	code: number
	message: string
	details: string[]
}

function FormatErrorMessage(error: ErroResponse, t: TFunction<'translation', undefined>): string | null {
	const pocId = error?.details

	const formattedMessage =
		pocId.length >= 1
			? t('transaction:NOTIFICATION.POINTS_REMOVE_DUPLICATE_LINE_ERROR', { pocId })
			: t('transaction:NOTIFICATION.POINTS_REMOVE_DUPLICATE_LINE_GENERIC_ERROR')

	const codeMsgMap: { [key: number]: string } = {
		36: formattedMessage,
	}

	return codeMsgMap[error?.code] ?? null
}

export function getErrorMessageRemovingPointsDuplicateRows(
	msg: string,
	t: TFunction<'translation', undefined>,
): string | null {
	const regex = /\{[^]*?\}/
	const match = regex.exec(msg)

	if (!match) {
		return null
	}

	try {
		const error: ErroResponse = JSON.parse(match[0])

		return FormatErrorMessage(error, t)
	} catch {
		return null
	}
}
