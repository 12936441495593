import React from 'react'
import InputSelect from 'components/formik/InputSelect'
import SingleFormTemplate, { TrackErrorToSegmentFunction } from 'components/templates/old/SingleFormTemplate'
import { useTranslation } from 'react-i18next'
import { FormikValues } from 'formik'
import * as Yup from 'yup'
import RouteLeavingGuardOptions from 'components/templates/old/SingleFormTemplate/model/RouteLeavingGuardOptions'
import TransactionType from 'domains/enums/TransactionTypeEnum'
import { PageLevelNotification } from '@/components/core'
import { Typography } from '@material-ui/core'
import RewardsOffer from 'routes/transaction/components/RewardsOffer/RewardsOffer'
import TransactionFields from 'routes/transaction/components/TransactionFields/TransactionFields'
import SubmitOptions from 'components/templates/old/SingleFormTemplate/model/SubmitOptions'
import { BASE_ROUTE } from '@/utils/constants'
import TransactionRefund from '../TransactionRefund/TransactionRefund'
import PointsTransfer from '../PointsTransfer/PointsTransfer'
import PointsRemoval from '../PointsRemoval/PointsRemoval'
import RemovalForServiceUtility from '../RemovalForServiceUtility/RemovalForServiceUtility'
import { TransactionErrorNotification } from './TransactionErrorNotification'

type Props = {
	transactionType?: TransactionType
	initialValues: FormikValues
	validationObject?: Yup.Lazy
	pageLevelNotification?: 'success' | 'error'
	showError: boolean
	customErrorMessage?: string | null
	handleChangeTransactionType: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void
	trackErrorToSegment: TrackErrorToSegmentFunction
	newTransactionId?: string
	transactionTypeOptions: {
		value: string
		label: string
	}[]
	onBreadcrumbBack: () => void
	submitOptions: SubmitOptions
	confirmationMessage: string
	onError?: (id: string, message: string) => void
}

const TransactionFormView: React.FC<Props> = ({
	transactionType,
	initialValues,
	validationObject,
	pageLevelNotification,
	showError,
	customErrorMessage,
	handleChangeTransactionType,
	trackErrorToSegment,
	newTransactionId,
	transactionTypeOptions,
	onBreadcrumbBack,
	submitOptions,
	confirmationMessage,
	onError,
}: Props) => {
	const { t } = useTranslation()

	const routeLeavingOptions: RouteLeavingGuardOptions = {
		whiteList: [BASE_ROUTE],
		alertMessage: t('DIALOG.ROUTE_LEAVING_MESSAGE_2', { name: t('transaction:NAME') }),
		alertTitle: t('DIALOG.ROUTE_LEAVING_HEADER'),
	}

	return (
		<SingleFormTemplate
			title={t('transaction:TITLE')}
			initialValues={initialValues}
			validationObject={validationObject}
			routeLeavingOptions={routeLeavingOptions}
			submitDialogProps={{
				title: t('transaction:CONFIRMATION_DIALOG_TITLE'),
				message: confirmationMessage || t('transaction:CONFIRMATION_DIALOG_MESSAGE'),
				buttonConfirmText: t('transaction:CONFIRMATION_DIALOG_CONFIRMATION'),
				buttonCancelText: t('BUTTON.GO_BACK'),
			}}
			submitOptions={submitOptions}
			trackErrorToSegment={trackErrorToSegment}
			breadCrumbLink={{ displayName: t('transaction:LOG.TITLE'), onBack: onBreadcrumbBack }}
			transactionType={transactionType}
		>
			<TransactionFields>
				{pageLevelNotification && (
					<PageLevelNotification type={pageLevelNotification} bottomSpacing={1} multiline>
						{pageLevelNotification === 'success' ? (
							<Typography variant="h5" id="transaction-notification-success" data-id={newTransactionId}>
								{transactionType === TransactionType.Refund && t('transaction:NOTIFICATION.REFUND_SUCCESS')}
								{transactionType === TransactionType.RewardsOffer && t('transaction:NOTIFICATION.SUCCESS')}
								{transactionType === TransactionType.PointsTransferFrom &&
									t('transaction:NOTIFICATION.POINTS_TRANSFER_SUCCESS')}
								{transactionType === TransactionType.PointsRemoval &&
									t('transaction:NOTIFICATION.POINTS_REMOVAL_SUCCESS')}
								{transactionType === TransactionType.ServiceUtility &&
									t('transaction:NOTIFICATION.POINTS_REMOVAL_SUCCESS')}
							</Typography>
						) : (
							<TransactionErrorNotification
								onError={onError}
								message={
									transactionType === TransactionType.PointsTransferFrom
										? t('transaction:NOTIFICATION.POINTS_TRANSFER_ERROR')
										: customErrorMessage ?? t('transaction:NOTIFICATION.ERROR')
								}
							/>
						)}
					</PageLevelNotification>
				)}
				<InputSelect
					id="transaction-type"
					name="transactionType"
					label={t('transaction:TRANSACTION_TYPE')}
					options={transactionTypeOptions}
					onChange={handleChangeTransactionType}
					showError={showError}
					autoFocus
					disableTopGutter
				/>
				{transactionType === TransactionType.Refund && <TransactionRefund showError={showError} onError={onError} />}
				{transactionType === TransactionType.RewardsOffer && <RewardsOffer showError={showError} onError={onError} />}
				{transactionType === TransactionType.PointsTransferFrom && (
					<PointsTransfer showError={showError} onError={onError} />
				)}
				{transactionType === TransactionType.PointsRemoval && <PointsRemoval showError={showError} onError={onError} />}
				{transactionType === TransactionType.ServiceUtility && (
					<RemovalForServiceUtility showError={showError} onError={onError} />
				)}
			</TransactionFields>
		</SingleFormTemplate>
	)
}

export default TransactionFormView
