import { RewardsOfferType, TransactionType } from '@/domains/enums'
import { normalizeTransactionType } from '@/domains/enums/TransactionTypeEnum'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useTransactionTypeOptions from '@/hooks/transaction/useTransactionsTypeList'
import { TransactionEvents, TransactionStore } from '@/stores'
import SubmitOptions from 'components/templates/old/SingleFormTemplate/model/SubmitOptions'
import ScreenName from 'domains/analytics/ScreenName'
import { useStoreMap } from 'effector-react'
import { FormikValues } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import AnalyticsService from 'services/analytics/AnalyticsService'
import { BASE_ROUTE } from 'utils/constants'
import * as Yup from 'yup'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { getErrorMessageRemovingPointsDuplicateRows } from '@/utils/getErrorMessageRemovingPointsDuplicateRows'
import { useHandleChangeTransactionType } from './hooks/useHandleChangeTrasactionType'
import { useHandleSubmitForm } from './hooks/useHandleSubmitForm'
import { useTrackFormValidationErrors } from './hooks/useTrackFormValidationErrors'
import TransactionFormView from './TransactionFormView'
import { getValidationObject } from './validationObjects'

const TransactionForm: React.FC = () => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const transactionTypeOptions = useTransactionTypeOptions()
	const { sendTrackingProps } = useTrackFormValidationErrors()
	const isPointsRemovalAndCategorization = useFeatureToggleV2(FeatureToggle.BULK_POINTS_REMOVAL)
	const isDimensionEnabled = useFeatureToggleV2(FeatureToggle.IDENTIFY_DIMENSION)

	const { handleSubmitForm, newTransactionId } = useHandleSubmitForm()
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()

	const { handleChangeTransactionType } = useHandleChangeTransactionType()

	const history = useHistory()

	const {
		selectedOrder,
		confirmationMessage,
		pageLevelNotificationType,
		showError,
		customErrorMessage,
		submitButtonEnabled,
		submitButtonVisible,
		transactionType,
	} = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			selectedOrder: state.selectedOrder,
			showError: state.showError,
			customErrorMessage: state.customErrorMessage,
			transactionType: state.transactionType,
			rewardsOfferType: state.rewardsOfferType,
			pageLevelNotificationType: state.pageLevelNotificationType,
			submitButtonVisible: state.isSubmitVisible,
			submitButtonEnabled: state.isSubmitEnabled,
			confirmationMessage: state.confirmationMessage,
		}),
	})

	const initialValues = React.useMemo(() => {
		let type: TransactionType | undefined
		if (transactionTypeOptions.length === 1) {
			type = transactionTypeOptions[0].value as TransactionType
		}

		return type === TransactionType.RewardsOffer
			? ({
					transactionType: type,
					rewardsOfferType: RewardsOfferType.MultiplePoc,
					combos: [{}],
				} as FormikValues)
			: ({
					transactionType: type,
					combos: [{}],
				} as FormikValues)
	}, [transactionTypeOptions])

	const validationObject = React.useMemo(
		() =>
			Yup.lazy((fields) =>
				Yup.object().shape(
					getValidationObject({
						transactionType: (fields as { transactionType: TransactionType }).transactionType,
						rewardsOfferType: (fields as { rewardsOfferType: RewardsOfferType }).rewardsOfferType,
						isPointsRemovalAndCategorizationEnabled: isPointsRemovalAndCategorization!,
						isIdentifyDimensionEnabled: isDimensionEnabled!,
					}),
				),
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedOrder, t, isPointsRemovalAndCategorization, isDimensionEnabled],
	)

	const handleBreadcrumbBack = () => {
		history.push(`${BASE_ROUTE}/transactions`)
	}

	const handleSubmitButtonClick = () => {
		TransactionEvents.setShowErrorForTransaction(true)
		sendAnalyticsServiceEventButtonClickedEvent(
			`${ScreenName.PointsManagement} - ${ScreenName.CreatePointsManagement} - ${
				normalizeTransactionType[transactionType!]
			}`,
			'Submit',
			'Submit',
		)
	}

	useEffect(() => {
		return () => TransactionEvents.resetForTransaction()
	}, [])

	useEffect(() => {
		if (transactionTypeOptions.length === 1) {
			TransactionEvents.setTransactionType(transactionTypeOptions[0].value as TransactionType)
		}
	}, [transactionTypeOptions])

	useEffect(() => {
		AnalyticsService.page(ScreenName.PointsManagement, userInfo)
	}, [userInfo])

	const submitOptions: SubmitOptions = {
		onSubmitButtonClick: handleSubmitButtonClick,
		onSubmitForm: handleSubmitForm,
		submitButtonEnabled:
			transactionType && [TransactionType.Refund, TransactionType.PointsTransferFrom].includes(transactionType)
				? submitButtonEnabled
				: true,
		submitButtonVisible:
			transactionType === TransactionType.Refund || transactionType === null ? submitButtonVisible : true,
	}

	const analyticError = (message: string) => {
		AnalyticsService.events.error({
			form_name: 'Create Points Transaction',
			screen_name: 'Points Management - Create Points Transaction',
			failure_reason: message,
			...userInfo,
		})
	}

	const onErrorHandle = (id: string, message: string) => {
		analyticError(message)
	}

	return (
		<TransactionFormView
			transactionType={transactionType ?? undefined}
			initialValues={initialValues}
			validationObject={validationObject}
			pageLevelNotification={pageLevelNotificationType ?? undefined}
			newTransactionId={newTransactionId}
			showError={showError}
			customErrorMessage={getErrorMessageRemovingPointsDuplicateRows(customErrorMessage, t)}
			handleChangeTransactionType={handleChangeTransactionType}
			trackErrorToSegment={sendTrackingProps}
			onBreadcrumbBack={handleBreadcrumbBack}
			transactionTypeOptions={transactionTypeOptions}
			submitOptions={submitOptions}
			confirmationMessage={confirmationMessage}
			onError={onErrorHandle}
		/>
	)
}
export default TransactionForm
