import React, { useCallback } from 'react'
import { Alert, Button, Dialog, Heading, SearchField, EmptyState as HexaEmptyState } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import {
	$createRedeemableItemStore,
	CreateRedeemableItemStoreEvents,
} from '@/stores/redeemableItems/CreateRedeemableItemStore'
import { useStore } from 'effector-react'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { CriticalPricePerPointConfigurationStoreEvents } from '@/stores/redeemableItems/CriticalPricePerPointStoreV2'
import { useAnalyticsServiceEventError } from '@/hooks/analyticsService/useAnalyticsServiceEventError'
import { useAnalyticsServiceEventRedeemableCreated } from '@/hooks/analyticsService/useAnalyticsServiceEventRedeemableCreated'
import { useFormContext } from 'react-hook-form'
import { CreateRedeemableItemUseCase } from '@/usecase'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { IAuditOperations } from '@/hooks/useAdminAuditLog'
import { ButtonInteraction, ScreenName } from '@/domains'
import { unformat } from '@/utils/currencyHelper'
import { MANAGE_REDEEMABLE, MODAL_CHANGE_DELAY } from '@/utils/constants'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useAnalyticsServiceEventTableSearched } from '@/hooks/analyticsService/useAnalyticsServiceEventTableSearched'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import useAuditLogRedeemables from '../../hooks/useAuditLogRedeemables'
import { EmptyState } from './EmptyState/EmptyState'
import useStyles from './AddRedeemableDialogStyle'
import { RedeemableItemCell } from '../RedeemableItem/RedeemableItemCell'
import { ItemCellLoader } from '../CreateRedeemableItem/content/Loader/ItemCellLoader'
import AddRedeemableItemFormProvider from './Form/AddRedeemableItemFormProvider'

interface AddRedeemableDialogProps {
	isOpened: boolean
	trigger?: React.ReactNode
}

export const AddRedeemableDialog: React.FC<AddRedeemableDialogProps> = ({ isOpened, trigger }) => {
	const isRedeemableApprovalFlowEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_APPROVAL_FLOW)
	const hasManageRedeemablePermission = useScopeAuthorization([MANAGE_REDEEMABLE])

	const state = useStore($createRedeemableItemStore)
	const userInfo = useGetUserInfo()
	const { sendAnalyticsServiceTableSearched } = useAnalyticsServiceEventTableSearched()

	const css = useStyles()
	const { t } = useTranslation()

	const minCpp = useFormatCurrency(state.item?.cppLimits?.min)
	const maxCpp = useFormatCurrency(state.item?.cppLimits?.max)
	const itemName = `input-ppp-${state.item?.id}`
	const variantNames = state.variants?.map((variant) => `input-ppp-${variant.id}`) || []
	const formFields = [itemName, ...variantNames]
	const Title = <Heading size="H3">{t('redeemable:ADD.TITLE')}</Heading>
	const shouldRenderEmptyState = !state.isSearching && !state.item && state.atInitialState
	const shouldRenderNotFound = !state.isSearching && !state.item && !state.atInitialState
	const shouldRenderAlertCostPerPoint =
		!state.isSearching && state.item && !hasManageRedeemablePermission && isRedeemableApprovalFlowEnabled
	const shouldRenderVariants = !state.isSearching && !shouldRenderNotFound && state.variants.length > 0

	const onDialogOpenChangeHandler = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_label: ButtonInteraction.Cancel,
			button_name: ButtonInteraction.Cancel,
			screen_name: ScreenName.CreateRedeemable,
			...userInfo,
		})

		return isOpened ? CreateRedeemableItemStoreEvents.reset() : CreateRedeemableItemStoreEvents.onOpen()
	}, [isOpened, userInfo])

	const handleSearch = useCallback(
		(value: string, event: React.FormEvent) => {
			event.preventDefault()
			CreateRedeemableItemUseCase.onSearch(value, true)
			sendAnalyticsServiceTableSearched({
				searchQuery: value,
				contentType: 'Create Redeemable',
				tableName: ' Create Redeemable - SKU',
				screenName: ScreenName.CreateRedeemable,
			})
		},
		[sendAnalyticsServiceTableSearched],
	)

	return (
		<div className={css.container} data-testid="add-redeemable-dialog">
			<AddRedeemableItemFormProvider formFields={formFields} notRequired={hasManageRedeemablePermission}>
				<Dialog.Root
					closeButton
					actions={
						<Actions
							item={state.item}
							errorOnPublish={state.errorOnPublish}
							isPublishing={state.isPublishing}
							variants={state.variants}
							isRedeemableApprovalFlowEnabled={isRedeemableApprovalFlowEnabled}
						/>
					}
					title={Title}
					trigger={trigger}
					open={isOpened}
					onOpenChange={onDialogOpenChangeHandler}
				>
					<p>{t('redeemable:ADD.DESCRIPTION')}</p>
					<SearchField.Root onSearch={handleSearch} placeholder={t('redeemable:SEARCH_PLACEHOLDER')} />

					<div className={css.emptyStateWrapper}>
						{shouldRenderEmptyState && (
							<div data-testid="add-redeemable-dialog-empty-state">
								<EmptyState />
							</div>
						)}

						{shouldRenderNotFound && (
							<div className={css.noResultWrapper}>
								<HexaEmptyState.SectionLevel
									description={t('redeemable:CREATE.ITEM_NOT_FOUND.DESCRIPTION')}
									ilustrationName="beesAlert"
								/>
							</div>
						)}
					</div>

					<div className={css.wrapperItem}>
						{state.isSearching && <ItemCellLoader />}

						{!state.isSearching && state.item && (
							<RedeemableItemCell {...state.item} externalView={hasManageRedeemablePermission} />
						)}
					</div>

					{shouldRenderAlertCostPerPoint && (
						<Alert
							message={
								state.item?.cppLimits
									? t('redeemable:ADD.COST_PER_POINT_RANGE', { minCpp, maxCpp })
									: t('redeemable:ADD.COST_PER_POINT_WITHOUT_RANGE')
							}
							type="warning"
							className={css.warning}
						/>
					)}

					{shouldRenderVariants &&
						state.variants.map((variant) => (
							<RenderVariants
								key={variant.id}
								variant={variant}
								hasManageRedeemablePermission={hasManageRedeemablePermission}
								isRedeemableApprovalFlowEnabled={isRedeemableApprovalFlowEnabled}
							/>
						))}

					<Alert
						className={css.alert}
						type="error"
						message={t('ERROR_MESSAGE.GENERIC_ERROR')}
						style={{ display: state.errorOnPublish || state.errorOnItems ? 'flex' : 'none' }}
					/>
				</Dialog.Root>
			</AddRedeemableItemFormProvider>
		</div>
	)
}

const RenderVariants: React.FC<{
	variant: RedeemableItem
	hasManageRedeemablePermission: boolean
	isRedeemableApprovalFlowEnabled?: boolean
}> = ({ variant, hasManageRedeemablePermission, isRedeemableApprovalFlowEnabled }) => {
	const { t } = useTranslation()
	const css = useStyles()

	const minCpp = useFormatCurrency(variant?.cppLimits?.min)
	const maxCpp = useFormatCurrency(variant?.cppLimits?.max)

	const shouldRenderVariants = !hasManageRedeemablePermission && isRedeemableApprovalFlowEnabled

	return (
		<div>
			<RedeemableItemCell {...variant} isVariant externalView={hasManageRedeemablePermission} />
			{shouldRenderVariants && (
				<Alert
					message={
						variant?.cppLimits
							? t('redeemable:ADD.COST_PER_POINT_RANGE', { minCpp, maxCpp })
							: t('redeemable:ADD.COST_PER_POINT_WITHOUT_RANGE')
					}
					type="warning"
					className={css.warning}
				/>
			)}
		</div>
	)
}

const Actions = ({
	item,
	errorOnPublish,
	isPublishing,
	variants,
	isRedeemableApprovalFlowEnabled,
}: {
	item: RedeemableItem | null
	errorOnPublish: boolean
	isPublishing: boolean
	variants: RedeemableItem[]
	isRedeemableApprovalFlowEnabled?: boolean
}) => {
	const css = useStyles()
	const { t } = useTranslation()

	const { sendAuditLogRedeemables } = useAuditLogRedeemables()
	const { sendAnalyticsServiceEventError } = useAnalyticsServiceEventError()
	const { sendAnalyticsServiceRedeemableCreated } = useAnalyticsServiceEventRedeemableCreated()

	const {
		handleSubmit,
		formState: { isDirty, isValid },
	} = useFormContext()

	const invalidPricePerPoint = isDirty && !isValid

	const onSubmit = useCallback(
		async (redeebleItens: RedeemableItem[]) => {
			try {
				await CreateRedeemableItemUseCase.publish({ redeemables: redeebleItens }, true, isRedeemableApprovalFlowEnabled)

				if (redeebleItens[0]) {
					sendAuditLogRedeemables({
						pricePerPoint: redeebleItens[0].pricePerPoint,
						SKU: redeebleItens[0].vendorItemId,
						operation: IAuditOperations.INSERT,
					})
					sendAnalyticsServiceRedeemableCreated({
						pricePerPoint: redeebleItens[0].pricePerPoint!,
						SKU: redeebleItens[0].vendorItemId,
						skuName: redeebleItens[0].itemName,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			} catch (error) {
				if (error instanceof Error) {
					sendAnalyticsServiceEventError({
						failureReason: error.message,
						screenName: ScreenName.CreateRedeemable,
					})
				}
			}
		},
		[sendAuditLogRedeemables, sendAnalyticsServiceRedeemableCreated, sendAnalyticsServiceEventError],
	)

	const buttonAction = useCallback(
		(pricePerPoints: { [key: string]: string }) => {
			const redeebleItens = [item as RedeemableItem, ...variants]

			redeebleItens.forEach((redeebleItem) => {
				if (pricePerPoints[`input-ppp-${redeebleItem.id}`]) {
					redeebleItem.pricePerPoint = unformat(pricePerPoints[`input-ppp-${redeebleItem.id}`])
				}
			})

			if (item) CreateRedeemableItemStoreEvents.setItem(redeebleItens[0])

			setTimeout(
				() =>
					CriticalPricePerPointConfigurationStoreEvents.onOpen({
						submit: () => {
							onSubmit(redeebleItens)
						},
						close: () => CreateRedeemableItemStoreEvents.onClose(),
						skuPricePerPoint: redeebleItens[0].pricePerPoint!,
					}),
				MODAL_CHANGE_DELAY,
			)
		},
		[item, variants, onSubmit],
	)

	return (
		<div className={css.actionContainer}>
			<Dialog.Close>
				<Button data-testid="add-redeemable-dialog-cancel-button" size="medium" variant="secondary">
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="add-redeemable-dialog-publish-button"
				size="medium"
				variant="primary"
				disabled={!item || invalidPricePerPoint}
				onClick={handleSubmit(buttonAction)}
				isLoading={isPublishing}
			>
				{errorOnPublish ? t('BUTTON.TRY_AGAIN') : t('BUTTON.ADD')}
			</Button>
		</div>
	)
}
