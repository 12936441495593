/**
 * CHALLENGES
 */
export const CHALLENGES_MODULE = 'membership-challenges-module'
export const PHOTO_CHALLENGES = 'membership-photo-challenges'
export const MARK_COMPLETE_CHALLENGES = 'membership-mark-complete-challenges'
export const PURCHASE_CHALLENGES = 'membership-purchase-challenges'
export const PURCHASE_CHALLENGES_MULTIPLE = 'membership-purchase-challenges-multiple'
export const DUPLICATE_CHALLENGE = 'membership-duplicate-challenges'
export const CANCEL_CHALLENGES = 'membership-cancel-challenges'
export const VOLUME_CHALLENGES = 'membership-volume-challenges'
export const TARGET_CHALLENGE_VOLUME_FIXED = 'membership-target-challenge-volume-fixed'
export const SPEND_BASED_CHALLENGE = 'membership-volume-challenge-spend-based'
export const VOLUME_FIXED_SINGLE = 'membership-volume-fixed-single'
export const DEV_CHALLENGE_THUMBNAIL = 'membership-dev-challenge-thumbnail'

/**
 * TRANSACTIONS
 */
export const POINTS_MANAGEMENT_MODULE = 'membership-points-management-module'
export const CREATE_REFUND = 'membership-create-refund'
export const CREATE_REFUND_REDEEMABLES = 'membership-create-refund-redeemables'
export const CREATE_REWARDS_OFFER = 'membership-create-rewards-offer'
export const CREATE_POINTS_TRANSFER = 'membership-create-points-transfer'
export const CREATE_POINTS_REMOVAL = 'membership-create-points-removal'
export const BULK_POINTS_REMOVAL = 'membership-bulk-points-removal'
export const NEW_TRANSACTION_LOGS = 'membership-new-transaction-logs'

/**
 *  CATEGORIES FOM SERVICE UTILITY
 */
export const ELECTRICITY_BILL = 'membership-eletricity-bill'
export const INTERNET_BILL = 'membership-internet-bill'
export const MOBILE_PHONE_BILL = 'membership-mobile-phone-bill'
export const WATER_BILL = 'membership-water-bill'
export const AWNING_SERVICE = 'membership-awning-service'
export const CABLE_TV_BILL = 'membership-cable-tv-bill'
export const CONCERT_TICKET_SERVICE = 'membership-concert-ticket-service'
export const COUNTER_INSTALLATION = 'membership-counter-installation'
export const FACADE_SERVICE = 'membership-facade-service'
export const PAINTING_SERVICE = 'membership-painting-service'
export const RECHARGE_SERVICE = 'membership-recharge-service'
export const VOUCHER_SERVICE = 'membership-voucher-service'

/**
 * DT COMBO MANAGEMENT
 */
export const COMBOS_MANAGEMENT_MODULE = 'membership-combo-management-module'
export const COMBOS_BULK_UPDATE = 'membership-combo-bulk-update'
export const COMBOS_3PD_INTEGRATION = 'membership-combos-3pd-integrations'

/**
 * REDEEMABLE ITEMS
 */
export const REDEEMABLE_ITEMS_MODULE = 'membership-redeemable-items-module'
export const REDEEMABLE_ITEMS_SORTBY_DISABLED = 'membership-redeemable-items-sortBy-disabled'
export const PRICE_PER_POINT_SETTINGS = 'membership-price-per-point-settings'
export const REDEEMABLE_SYNC_3PD = 'membership-admin-3pd-reedemable-sync'
export const REDEEMABLE_COST_PER_POINT_PERMISSION = 'membership-permission-redeemables-cpp'
export const NEW_REDEEMABLE_LOGS = 'membership-dev-new-redeemable-logs'
export const REDEEMABLE_RESTRICT_CPP_VISIBILITY = 'membership-redeemable-restrict-cpp-visibility'
export const REDEEMABLE_APPROVAL_FLOW = 'membership-redeemable-approval-flow'

/**
 * RULES
 */
export const EARNING_RULES_MODULE = 'membership-earning-rules-module'
export const CREATE_EARNING_RULES = 'membership-create-earning-rules'
export const SHOW_EXCLUSION_LIST = 'membership-dev-show-exclusion-list'
export const SHOW_UPLOAD_CSV_OPTION = 'membership-earning-sku-step-csv'

/**
 * TRANSACTION HISTORY
 */
export const TRANSACTION_HISTORY_MODULE = 'membership-transaction-history-module'

/**
 *  ADD POINTS TRANSACTION
 */

export const IDENTIFY_DIMENSION = 'membership-identify-dimension'
export const IDENTIFY_SKU = 'membership-identify-sku'

/**
 * REWARDS PERMISSION
 */
export const REWARDS_PERMISSION_MODULE = 'membership-rewards-permission-module'

/**
 * MULTIPLE ZONES LOGIN
 */
export const MULTIPLE_ZONES_LOGIN = 'membership-multiple-zones-login'

/*
 * SELECT PROGRAM
 */
export const SELECT_PROGRAM = 'membership-select-program'

/**
 * SELECT TIERS
 */
export const SELECT_TIERS = 'membership-select-tiers'

/*
 * MULTIVENDOR
 */
export const DEV_MULTIVENDOR_SELECT = 'membership-dev-multivendor-select'
export const DEV_MULTIVENDOR_VALIDATE_ACCOUNT = 'membership-dev-multivendor-validate-account'
export const DEV_MULTIVENDOR_REDEEMABLES_BY_VENDOR = 'membership-dev-multivendor-redeemables-by-vendor'
export const DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR = 'membership-dev-multivendor-earning-rules-by-vendor'
/**
 * [DEV]
 */
export const DEV_MULTI_VENDOR_EARNING_RULES = 'membership-dev-multi-vendor-earning-rules'
export const DEV_MULTI_VENDOR_COMBOS = 'membership-dev-multi-vendor-combos'
export const DEV_COMBOS_BULK_SELECTION = 'membership-dev-combo-bulk-selection'
export const DEV_EDIT_AND_REMOVE_MULTIPLES_COMBOS = 'membership-dev-edit-and-remove-multiples-combos'
export const DEV_MULTIVENDOR_REWARDSOFFER_BULK = 'membership-dev-multivendor-rewardsoffer-bulk'
export const DEV_CHALLENGE_VISIONID = 'membership-dev-challenge-visionid'
/**
 *
 */
export const CREATE_REMOVAL_FOR_SERVICE_UTILITY = 'membership-create-removal-for-service-utility'

export const ZONE_CONFIGURATION_MODULE = 'membership-zone-configuration-module'

/**
 * RULES V2
 */
export const devUseRulesV2MFE = 'membership-dev-use-rules-mfe'
