import { useCallback } from 'react'
import useLoadRedeemablesAndCombos from '@/hooks/combo/useLoadRedeemablesAndCombos'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { ComboEvents, SettingsConfigurationEvents } from '@/stores'
import { LoadDefaultConfigurationUseCase, LoadProgramUseCase } from '@/usecase'
import * as FeatureToggle from '@/utils/featureToggle'
import { useGetMultiplesFeatureToggles } from '../toggle/useGetMultiplesFeatureToggles'

export interface LoadCombosSettingsResult {
	loadCombosSettings: () => Promise<void>
	togglesReady: boolean
}

export const useLoadCombosSettings = (): LoadCombosSettingsResult => {
	const { loadRedeemablesAndCombos } = useLoadRedeemablesAndCombos()
	const { selectedTierKey } = useCurrentTier()

	const { togglesReady, featuresListByToggleName } = useGetMultiplesFeatureToggles([
		FeatureToggle.ZONE_CONFIGURATION_MODULE,
		FeatureToggle.DEV_MULTIVENDOR_REDEEMABLES_BY_VENDOR,
	])

	const isSettingConfigByTier = featuresListByToggleName[FeatureToggle.ZONE_CONFIGURATION_MODULE]
	const isToggleMultivendorRedeemablesByVendorEnabled =
		featuresListByToggleName[FeatureToggle.DEV_MULTIVENDOR_REDEEMABLES_BY_VENDOR]

	const loadProgramData = useCallback(async () => {
		const program = await LoadProgramUseCase.execute()
		if (!program) {
			throw new Error('No program!')
		}
		ComboEvents.setProgramDataForCombo(program)

		if (isToggleMultivendorRedeemablesByVendorEnabled === false) {
			await loadRedeemablesAndCombos(program.id)
		}
	}, [loadRedeemablesAndCombos, isToggleMultivendorRedeemablesByVendorEnabled])

	const loadDefaultConfiguration = useCallback(async () => {
		const settings = await LoadDefaultConfigurationUseCase.execute({
			selectedTierKey: selectedTierKey!,
		})
		if (!settings) {
			throw new Error('No settings!')
		}
		SettingsConfigurationEvents.setSettingsData(settings)
		if (isToggleMultivendorRedeemablesByVendorEnabled === false) {
			await loadRedeemablesAndCombos(settings.settingId!)
		}
	}, [loadRedeemablesAndCombos, selectedTierKey, isToggleMultivendorRedeemablesByVendorEnabled])

	const loadData = useCallback(async (): Promise<void> => {
		try {
			ComboEvents.setCombosPageReady(false)
			if (isSettingConfigByTier) {
				await loadDefaultConfiguration()
			} else if (isSettingConfigByTier === false) {
				await loadProgramData()
			}
		} catch {
			ComboEvents.showErrorInCombosData()
		} finally {
			ComboEvents.setCombosPageReady(true)
		}
	}, [loadProgramData, loadDefaultConfiguration, isSettingConfigByTier])

	return {
		loadCombosSettings: loadData,
		togglesReady,
	}
}
